.field-line,
.button-line {
  padding: $m-size;
}

.error-message {
  padding: 0 $m-size;
  color: tomato;
}

.success-message {
  padding: 0 $m-size;
  color: green;
}
