.agent-info-table {
  border-collapse: collapse;
  width: 100%;

  th,
  td {
    text-align: center;
    padding: 8px;
  }

  tr:nth-child(odd) {
    background-color: #f2f2f2;
  }
}

.agent-header {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

#agent-exit-button {
  display: flex;
  justify-content: flex-end;
}
