.rate-group-container {
  display: flex;
}

.property-template {
  margin: $xl-size;
}

.policy textarea:hover {
  transition: background-color 0.5s ease;
  background-color: rgb(219, 219, 219);
}

.policy textarea:focus {
  transition: background-color 0.5s ease;
  background-color: white;
}
