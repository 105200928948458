/*=============================================
=            LONDOLOZI FONTS AND ICONS            =
=============================================*/

@font-face {
  font-family: "trajan";
  src: url("trajan-pro-webfonts/trajanpro-regular-webfont.eot");
  src: url("trajan-pro-webfonts/trajanpro-regular-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("trajan-pro-webfonts/trajanpro-regular-webfont.woff") format("woff"),
    url("trajan-pro-webfonts/trajanpro-regular-webfont.ttf") format("truetype"),
    url("trajan-pro-webfonts/trajanpro-regular-webfont.svg#trajan_proregular")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "londo";
  src: url("londo-icon-font/fonts/londo.eot?v=10");
  src: url("londo-icon-font/fonts/londo.eot?v=10&?#iefix")
      format("embedded-opentype"),
    url("londo-icon-font/fonts/londo.woff?v=10") format("woff"),
    url("londo-icon-font/fonts/londo.ttf?v=10") format("truetype"),
    url("londo-icon-font/fonts/londo.svg#londo") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "leopards";
  src: url("leopards-icon-font/fonts/leopards.eot?v=5");
  src: url("leopards-icon-font/fonts/leopards.eot?v=5&#iefix")
      format("embedded-opentype"),
    url("leopards-icon-font/fonts/leopards.woff?v=5") format("woff"),
    url("leopards-icon-font/fonts/leopards.ttf?v=5") format("truetype"),
    url("leopards-icon-font/fonts/leopards.svg#leopards") format("svg");
  font-weight: normal;
  font-style: normal;
}

/*=====  LONDO  ======*/

[data-icon]:before {
  font-family: "londo" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "londo" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*=====  LEOPARDS  ======*/

.leopard-icons[class^="icon-"]:before,
.leopard-icons[class*=" icon-"]:before,
.leopards-icon-font {
  font-family: "leopards" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-twitter:before {
  content: "\61";
}
.icon-tumblr:before {
  content: "\62";
}
.icon-youtube-play:before {
  content: "\63";
}
.icon-pinterest:before {
  content: "\64";
}
.icon-google-plus:before {
  content: "\66";
}
.icon-facebook:before {
  content: "\67";
}
.icon-vimeo:before {
  content: "\68";
}
.icon-left-open-big:before {
  content: "\6a";
}
.icon-right-open-big:before {
  content: "\6b";
}
.icon-link:before {
  content: "\6c";
}
.icon-linkedin:before {
  content: "\6d";
}
.icon-tumblr-1:before {
  content: "\6e";
}
.icon-menu:before {
  content: "\6f";
}
.icon-menu-1:before {
  content: "\70";
}
.icon-search-find:before {
  content: "\71";
}
.icon-down-open-big:before {
  content: "\69";
}
.icon-up-open-big:before {
  content: "\72";
}
.icon-home-house-streamline:before {
  content: "\73";
}
.icon-home:before {
  content: "\74";
}
.icon-plus:before {
  content: "\75";
}
.icon-bubble:before {
  content: "\76";
}
.icon-instagram:before {
  content: "\6e";
}
.icon-social-whatsapp-outline:before {
  content: "\65";
}
.icon-lock:before {
  content: "\54";
}
.icon-star:before {
  content: "\55";
}
.icon-hex2:before {
  content: "\56";
}
.icon-hex1:before {
  content: "\57";
}
.icon-mail:before {
  content: "\58";
}
.icon-cart-1:before {
  content: "\59";
}
.icon-cart-plus:before {
  content: "\5a";
}
.icon-bookmark:before {
  content: "\30";
}
.icon-bookmarks:before {
  content: "\31";
}
.icon-favourite:before {
  content: "\32";
}
.icon-heart-empty:before {
  content: "\33";
}
.icon-star-1:before {
  content: "\34";
}
.icon-star-empty:before {
  content: "\35";
}
.icon-screen-full:before {
  content: "\36";
}
.icon-screen-normal:before {
  content: "\37";
}
.icon-resize-full:before {
  content: "\21";
}
.icon-fullscreen-exit:before {
  content: "\23";
}
.icon-fullscreen:before {
  content: "\24";
}
.icon-contract:before {
  content: "\38";
}
.icon-aperture-1:before {
  content: "\39";
}
.icon-aperture:before {
  content: "\22";
}
.icon-max:before {
  content: "\25";
}
.icon-min:before {
  content: "\26";
}
.icon-share:before {
  content: "\27";
}
.icon-easel:before {
  content: "\28";
}
.icon-info:before {
  content: "\29";
}
.icon-info-1:before {
  content: "\2a";
}
.icon-info-2:before {
  content: "\2b";
}
.icon-download:before {
  content: "\2c";
}
.icon-android-done:before {
  content: "\2d";
}

/*=====  LEOPARDS  ======*/

[data-leopards-icon]:before {
  font-family: "leopards" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="leopards-icon"]:before,
[class*="leopards-icon"]:before {
  font-family: "leopards" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-flip:before {
  content: "\61";
}
.icon-foot:before {
  content: "\66";
}
.icon-skull:before {
  content: "\67";
}
.icon-compass:before {
  content: "\68";
}
.icon-feather:before {
  content: "\69";
}
.icon-help-circled:before {
  content: "\6a";
}
.icon-heart:before {
  content: "\6b";
}
.icon-leaf:before {
  content: "\6c";
}
.icon-camera:before {
  content: "\6d";
}
.icon-trophy:before {
  content: "\6e";
}
.icon-globe:before {
  content: "\70";
}
.icon-question:before {
  content: "\71";
}
.icon-paw:before {
  content: "\72";
}
.icon-binoculars:before {
  content: "\73";
}
.icon-mars:before {
  content: "\74";
}
.icon-venus:before {
  content: "\75";
}
.icon-book:before {
  content: "\76";
}
.icon-female-symbol-1:before {
  content: "\77";
}
.icon-heart-small:before {
  content: "\78";
}
.icon-male-symbol-1:before {
  content: "\79";
}
.icon-leaf-2:before {
  content: "\7a";
}
.icon-microscope:before {
  content: "\41";
}
.icon-female-symbol-2:before {
  content: "\42";
}
.icon-campfire:before {
  content: "\43";
}
.icon-brush:before {
  content: "\44";
}
.icon-male-symbol-2:before {
  content: "\45";
}
.icon-male:before {
  content: "\46";
}
.icon-asl:before {
  content: "\47";
}
.icon-elusive-icons:before {
  content: "\48";
}
.icon-heart-circled:before {
  content: "\49";
}
.icon-sun-fill:before {
  content: "\4a";
}
.icon-heart-fill:before {
  content: "\4b";
}
.icon-zoo:before {
  content: "\4c";
}
.icon-danger:before {
  content: "\4d";
}
.icon-female:before {
  content: "\65";
}
.icon-dice:before {
  content: "\4e";
}
.icon-leopard:before {
  content: "\4f";
}
.icon-dice-1:before {
  content: "\50";
}
.icon-meercat:before {
  content: "\51";
}
.icon-scroll:before {
  content: "\62";
}
.icon-crown:before {
  content: "\63";
}
.icon-social-myspace:before {
  content: "\64";
}
.icon-flow-tree:before {
  content: "\6f";
}
.icon-newsvine:before {
  content: "\52";
}
.icon-flickr:before {
  content: "\32";
}
.icon-cub:before {
  content: "\53";
}
.icon-stork:before {
  content: "\5a";
}
.icon-climb:before {
  content: "\55";
}
.icon-injured:before {
  content: "\56";
}
.icon-lie:before {
  content: "\57";
}
.icon-run:before {
  content: "\58";
}
.icon-sit:before {
  content: "\59";
}
.icon-stalk:before {
  content: "\30";
}
.icon-parents:before {
  content: "\54";
}
.icon-spot:before {
  content: "\33";
}
.icon-filmstrip:before {
  content: "\31";
}
.icon-gallery:before {
  content: "\34";
}
.icon-map:before {
  content: "\35";
}

.icon-bag:before {
  content: "\4b";
}
.icon-camera-1:before {
  content: "\4c";
}
.icon-feather-1:before {
  content: "\4d";
}
.icon-shape-complex:before {
  content: "\4e";
}
.icon-shape-simple:before {
  content: "\4f";
}
.icon-kingfisher:before {
  content: "\50";
}
.icon-lion:before {
  content: "\51";
}
.icon-tortoise:before {
  content: "\52";
}
.icon-lion2:before {
  content: "\53";
}

.authentication-card__heading::before {
  content: "Reservations";
  background-image: url("https://blog.londolozi.com/wp-content/themes/londolozi/img/londolozi-logo-web-med.png");
  background-position: top center;
  background-repeat: no-repeat;
  padding-top: 130px;
  width: 100%;
  display: block;
  color: #aaa;
  font-size: 13px;
  letter-spacing: 3.1px;
  text-indent: 4px;
}

.authentication-card__heading__text {
  font-size: 16px;
}
