html {
  font-size: 62.5%;
}

html,
body {
  font-family: Georgia, "Times New Roman", Times, serif;
}

// body {
//     font-family: Roboto, sans-serif;
//     font-size: $s-size;
// }

.page-layout {
  display: grid;
  // font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  min-height: 100%;
  min-width: 320px;
  color: #231f20;
  font-size: 15px;
  line-height: 20px;
  position: relative;
  font-family: "trajan";
}

// img-responsive, .thumbnail>img, .thumbnail a>img, .carousel-inner>.item>img, .carousel-inner>.item>a>img {
//     display: block;
//     max-width: 100%;
//     height: auto;
// }

img.img-responsive {
  filter: brightness(122%);
}

.side-bar {
  grid-row-start: 1;
  grid-row-end: 2;
  overflow: hidden;
}

.content {
  // overflow: auto;
  // padding-top: 10px;
  padding-bottom: 200px;
  // padding: $l-size $l-size;
}

.headercontent {
  z-index: 1001;
  width: 100%;
  height: 100px;
  position: fixed;
  background: rgba(255, 255, 255, 0.97);
  border-bottom: 1px solid black;
  padding: 5px 0 !important;
}

.lion-logo {
  width: 300px;
  position: relative;
  bottom: 0px;
  left: 30px;
}

.menu-button {
  // width: 6%;
  position: relative;
  bottom: 45px;
  right: 90px;
  float: right;
}

.menu-button > div > span {
  height: 2px !important;
}

.menu-button:hover > div > span {
  background: rgb(55, 152, 251) !important;
}

.menu-button-active > div > span {
  background: rgb(55, 152, 251) !important;
  height: 2px !important;
}
.menu-button-active {
  // width: 6%;
  position: relative;
  bottom: 45px;
  right: 90px;
  float: right;
}

.profile-button {
  // width: 4%;
  position: relative;
  bottom: 61.5px;
  right: 15px;
  float: right;
}

a {
  color: #8b8b8b;
  font-weight: bold;
  text-decoration: none;
  transition: color 0.4s;
}

a:hover {
  color: red;
}

a {
  transition: 0.2s;
  outline: 0;
  text-decoration: none;
}

.logo-text {
  margin-left: 103px;
  font-family: Trajan Pro !important;
  bottom: 60px;
  // float: left;
  // margin: 17px 0 0 20px;
  position: absolute;
}

.site-title {
  margin: 0;
  font-family: trajan;
  font-size: 18px;
  letter-spacing: 6.5px;
}

h1 {
  color: #231f20;
  // font-size: 24px;
  letter-spacing: 0.1px;
  font-weight: 500;
  line-height: 1.1;
}

// h1, .h1 {
//     font-size: 36px;
// }

.site-title span.outside-letters {
  font-size: 20px;
  margin-right: 10px;
  letter-spacing: 0;
}

.inside-letters {
  margin: -4.5px;
  text-transform: uppercase;
  font-size: 17px;
  font-family: "trajan" !important;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.site-title span.outside-letters.logo-i {
  margin-left: 4px;
  font-size: 18px;
}

div {
  display: block;
  // position: relative;
}

// html, body {
//     font-family: Georgia,"Times New Roman",Times,serif;
//     min-height: 100%;
//     min-width: 320px;
// }

// body {
//     font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
//     font-size: 14px;
//     line-height: 1.42857143;
//     color: #333;
//     background-color: #fff;
// }

.site-subtitle {
  margin: 0;
  font-family: trajan;
  color: #aaa;
  padding: 14px 0 0 40px;
  font-size: 26px;
  letter-spacing: 4px;
  position: absolute;
  text-transform: uppercase;
}

h2 {
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: lighter;
}

.bold-modal-header {
  font-weight: bold;
}

.iconhover {
  z-index: 1000;
  position: absolute;
  bottom: -12.5px;
  color: grey;
}

.iconhover:hover {
  color: black;
}

.menuitem {
  font-style: italic;
  font-family: Georgia, "Times New Roman", Times, serif;
}

.booking-page-title {
  margin: -3.5px;
  letter-spacing: 3.5px;
  text-align: center;
  font-family: Georgia, "Times New Roman", Times, serif;
  padding: 31px;
}

.booking-page-title::first-letter {
  font-size: 120%;
}
