/*=============================================
=            LODGEFORCE DESIGN TWEAKS         =
=============================================*/

:root {
  --londolozi-blue: #3797fb;
}

/*=============================================
=                 GENERAL                    =
=============================================*/

body {
  font-size: 14px;
  line-height: 1.42857143;
  color: #333;
}

div {
  font-family: Georgia, Times New Roman, Times, serif !important;
  color: #333;
}

a {
  color: var(--londolozi-blue);
  transition: 0.2s;
  font-weight: normal;
}

a:hover {
  color: #2a6496;
}

/*===== TYPOGRAPHY ======*/

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  line-height: 1.1;
  font-weight: normal;
}

h2,
.booking-page-title > span:first-child,
.calendar.booking-card::before,
.bookingmodalheaders span,
#view-agents-info > .booking-card > div > div > span:first-child,
#view-manage-bookings > .booking-card > div > div > span:first-child,
.agent-header > div > span:first-child,
.submitbookingrequest button span,
div[name="itinerary"] > .booking-card::before,
#view-add-agents::before,
#trade-information::before,
#view-manage-lodge:before,
.edit-lodge-policy textarea + div {
  text-align: center;
  font-family: "trajan";
  font-size: 18px;
  margin: 21px 0 40px;
  line-height: 1;
  letter-spacing: 2px;
  color: #231f20;
  font-style: normal !important;
}

.search-dates-header2,
.search-dates-header5 {
  font-style: italic;
}

.booking-card-searchbar div,
.search-dates-header4_1,
.search-dates-header4_2 {
  font-size: 15px;
  border-radius: 0;
}

.CampCarouselButtonCampName,
.modal-nights {
  color: #888;
  font-size: 14px;
  padding-bottom: 6px;
  font-style: italic;
}

.CampCarouselButtonDate,
.modal-dates,
.totals-booking-modal {
  color: #a8a8a8;
  font-size: 14px;
}

/*===== LAYOUT ======*/

html {
  min-height: 100%;
  height: auto;
}

body,
#app,
#app > div,
#app > div > .page-layout {
  height: 100%;
  position: relative;
  /*	min-height: 100vh; */
}

#app > div > .page-layout {
  display: flex;
  flex-direction: column;
}

.content > .booking-page {
  padding: 30px 30px 0px 30px;
  height: auto;
  display: block;
  width: 100%;
  min-height: calc(100vh - 153px);
}

.content .booking-page .booking-page {
  padding: 0;
}

.booking-page > div > div:first-child {
  display: none !important;
}

.content {
  padding: 93px 0 60px;
  max-width: 1360px;
  margin: 0 auto;
  width: 100%;
  min-height: 100vh;
  height: 100%;
}

.booking-card,
.booking-card-searchbar,
.booking-card__warning {
  margin: 0;
  border: none;
  padding: 0;
  border-radius: 0;
}

/*===== LOADING ======*/

div[mode="indeterminate"] {
  justify-content: center;
  text-align: center;
  width: 100% !important;
}

div[mode="indeterminate"] circle {
  stroke: var(--londolozi-blue);
}

.loading,
div[mode="indeterminate"],
.matching-list .owl-carousel.owl-drag .owl-item {
  background: url("https://blog.londolozi.com/wp-content/themes/londolozi/img/loading.gif")
    no-repeat center center;
}

div[mode="indeterminate"] > * {
  display: none !important;
}

/*===== BUTTONS ======*/

.search-button-style,
.search-button-style div,
.search-button-style span,
.search-config-box-donebutton,
.confirm-button-raised,
.confirm-button-raised div,
.confirm-button-raised div span,
.add-another-room span,
.ReactTable .-pagination .-btn,
.agent-info-table + button span,
.bookingdetail2-card > div button span,
.button-login > button div span,
.generate-token-button > button div span,
#view-add-agents .email-field ~ button span {
  font-family: trajan;
  text-transform: capitalize !important;
  font-size: 16px !important;
  letter-spacing: 0.6px !important;
  font-weight: normal !important;
  color: #000;
  border-radius: 0;
}

.search-button-style span {
  padding-left: 30px !important;
  padding-top: 2px;
}

.search-button-style span:nth-child(2)::before {
  content: "q";
  text-transform: lowercase;
  font-family: londo;
  font-size: 21px;
  position: absolute;
  left: 0;
  top: 1px;
  color: #333;
}

.search-button-style {
  height: 40px !important;
}

.incr-dec-button {
  cursor: pointer;
}

button[disabled=""],
.ReactTable .-pagination .-btn[disabled] {
  background: #f5f5f5 !important;
  opacity: 1;
  color: rgb(153, 153, 153);
  cursor: default;
  border-color: rgb(189, 189, 189);
}

/*===== FORMS ======*/

input[type="text"],
input[type="number"],
input[type="email"],
.ReactTable .rt-thead.-filters input,
.ReactTable .rt-thead.-filters select,
.ReactTable .-pagination input,
.ReactTable .-pagination select {
  padding: 6px;
  border: 1px solid #d0d0d0;
  background: #fafafa;
  font-size: 15px;
  line-height: 1.3;
  border-radius: 0;
  font-weight: normal;
}

input[type="text"],
input[type="number"],
input[type="email"] {
  padding: 6px 22px 6px 2%;
}

input[type="checkbox"] + div > div > div > svg {
  fill: var(--londolozi-blue) !important;
}

input[type="checkbox"] + div > div > div > svg:first-child {
  fill: #888 !important;
}

textarea {
  overflow: auto;
  padding: 9px 10px 0;
  background: rgb(245, 245, 245) none repeat scroll 0% 0%;
  border: 1px solid rgb(221, 221, 221);
  font-size: 15px;
  line-height: 1.5;
  width: 100% !important;
}

/*=============================================
=                 HEADER                    =
=============================================*/

.headercontent {
  height: auto;
  background: rgba(255, 255, 255, 0.97);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  border: none;
  z-index: 1110;
  padding: 0 !important;
}

.header-inner {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 26px !important;
  margin: 0 auto;
  max-width: 1360px;
}

/*===== LOGO ======*/

.logo-text {
  margin: 8px 0 0 12px;
  position: relative;
  bottom: auto;
  display: flex;
  flex-direction: column;
}

.lion-logo {
  display: flex;
  width: auto;
  left: auto;
  align-items: center;
  margin-right: auto;
}

.lion-logo > a {
  display: block;
}

.lion-logo img {
  padding: 5px;
  max-width: 83px;
  filter: none;
  display: block;
}

.site-subtitle {
  position: relative;
  padding: 2px 0 0 60px;
  line-height: 1.2;
  font-size: 26px;
}

.iconhover {
  bottom: 1px;
  color: #e6e6e6;
  left: 5px;
}

.iconhover svg.iconhover {
  width: 36px;
  height: 36px;
  transition: 0.2s !important;
}

.iconhover:hover svg.iconhover {
  color: rgb(55, 152, 251);
}

.site-subtitle::first-letter {
  font-size: 1.15em;
}

/*===== BUTTONS ======*/

.profile-button {
  display: flex;
  right: auto;
  float: none;
  bottom: auto;
  margin: 0 14px 0 10px;
}

.profile-button svg {
  width: 40px;
  height: 40px;
  fill: #c3c3c3;
}

.profile-button button {
  padding: 0;
}

.menu-button,
.menu-button-active {
  display: flex;
  bottom: auto;
  right: auto;
  float: none;
  padding: 10px;
  position: relative;
  height: 46px;
  width: 46px;
  margin: 8px 8px 0;
}

.menu-button > div,
.menu-button-active > div {
  width: 25px !important;
  height: 20px !important;
  cursor: pointer;
  transform: none;
}

.menu-button > div > span:nth-child(2) {
  top: 8px !important;
}

.menu-button > div > span:nth-child(3) {
  top: 16px !important;
  margin: 0;
  bottom: auto;
  transform: none !important;
}

/*=============================================
=                 MENU                    =
=============================================*/

.bm-menu-wrap {
  top: 93px;
  background-size: 300px;
  background-position: bottom right;
  height: calc(100% - 94px) !important;
  background-color: #fafafa;
  width: 450px !important;
}

.bm-menu {
  padding: 13px 0 0 0;
}

.bm-overlay {
  background: rgba(0, 0, 0, 0.65) !important;
}

.menuitem div {
  color: #231f20 !important;
  font-size: 15px;
}

.menuitem > div > div {
  padding: 0px 16px 0px 70px !important;
}

.menuitem div svg {
  left: 15px !important;
  top: 1px !important;
  fill: #afafaf !important;
}

span[role="menuitem"] {
  padding: 6px 0 !important;
}

div[role="menu"] {
  padding: 0 !important;
}

/*=============================================
=                 VIEWS                    =
=============================================*/

#view-agents-info .booking-card,
#view-manage-bookings .booking-card {
  margin: 0;
  padding: 0;
  border: none;
}

#view-agents-info > .booking-card > div:first-child > div:first-child,
#view-manage-bookings > .booking-card > div:first-child > div:first-child {
  padding: 0 !important;
  margin: 18px 0 26px;
}

#view-agents-info > .booking-card > div > div > span:first-child,
#view-manage-bookings > .booking-card > div > div > span:first-child {
  font-size: 18px !important;
  color: #231f20 !important;
  margin: 0;
  line-height: 1.07 !important;
}

#view-agents-info > .booking-card > div > div > span:last-child,
#view-manage-bookings > .booking-card > div > div > span:last-child {
  color: #a4a4a4 !important;
  font-style: italic;
  text-align: center;
}

#view-agents-info > div:first-child > div:first-child > br,
#view-manage-bookings > div:first-child > div:first-child > br {
  display: none;
}

/*=============================================
=            MAKE A BOOKING                   =
=============================================*/

.booking-page > div > div:first-child {
  display: none !important;
}

.booking-card-search-parent {
  width: 100%;
  display: flex;
  justify-content: center;
}

.dropdownsearch {
  height: auto;
}

.dropdownsearch::after,
.booking-card-dropdown-calendar div button::after,
.bookingdetail2-card > div > div > div div button::after,
.manage-booking-popup
  .ReactTable
  + div
  > div
  > div:last-child
  > div
  div
  button::after {
  content: "\69";
  position: absolute;
  right: 10px;
  font-family: "londo";
  color: #666;
  font-size: 18px;
  cursor: pointer;
  z-index: 1;
  pointer-events: none;
}

.dropdownsearch svg,
.manage-booking-popup
  .ReactTable
  + div
  > div
  > div:last-child
  > div
  div
  button
  svg {
  display: none;
}

.dropdownsearch em {
  font-style: normal !important;
}

.search-dates-header2 {
  padding-bottom: 4px;
}

.search-dates-header4_1 {
  float: left;
  clear: none;
  width: auto;
}

.search-dates-header4_2 {
  float: right;
  clear: none;
  width: calc(50% - 16px);
}

.dropdownsearch div {
  height: 40px;
}

.dropdownsearch > div > div {
  padding: 8px 34px 0 20px;
  box-sizing: border-box;
  text-indent: 0;
}

/*===== DATEPICKER ======*/

.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover,
.CalendarDay__selected:hover {
  background: #68b3ff;
  color: white;
}

.DateInput {
  padding: 10px 6px 7px;
  height: 40px;
}

.DateRangePicker.DateRangePicker_1 {
  width: 100%;
}

.DateRangePickerInput__withBorder {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.search-dates {
  width: 100% !important;
}

.DateInput_input {
  font-family: trajan;
  color: #111 !important;
  font-weight: normal;
  padding: 0;
  line-height: 17px;
  vertical-align: middle;
  font-size: 16px;
  letter-spacing: 0.5px;
}

.DateInput.DateInput_1 {
  width: calc(50% - 8px);
  padding: 0;
  border: none;
  background: transparent;
}

.DateInput.DateInput_1 input {
  height: 40px;
  line-height: 40px;
  padding-top: 5px;
  padding-bottom: 0px;
}

.DateInput.DateInput_1 input::-webkit-input-placeholder {
  line-height: 1.2;
}

.DateRangePicker_picker {
  top: 40px !important;
  border-top: 4px solid var(--londolozi-blue);
}

.CalendarDay__selected,
.CalendarDay__selected_span {
  background: #3798fb;
  color: #fff;
  border: none;
}

.CalendarMonth_caption {
  padding-bottom: 42px;
  padding-top: 16px;
}

.DayPicker_weekHeader_li,
.DayPicker_weekHeader_li small {
  font-style: italic;
  font-size: 14px;
  color: #aaa;
  height: 40px;
}

.CalendarDay__default {
  height: 33px !important;
}

.DayPickerNavigation_button__horizontal {
  top: 12px;
  border-radius: 0;
  width: 39px;
  height: 33px;
}

.DayPickerNavigation_button__horizontal::after {
  content: "j";
  text-transform: lowercase;
  font-family: "londo";
  font-size: 21px;
  position: absolute;
  line-height: 1;
  color: #333;
  opacity: 0.8;
  transition: 0.2s;
  left: 50%;
  margin: 0 0 0 -11px;
  top: 6px;
}

.DayPickerNavigation_button__horizontal.DayPickerNavigation_rightButton__horizontal::after {
  content: "k";
}

.DayPickerNavigation_button__horizontal svg,
.ReactModal__Content > button:first-child svg {
  display: none !important;
}

.DayPicker_weekHeader {
  top: 51px;
}

/*===== GUEST PICKER ======*/

#menu- > div {
  border-radius: 0;
}

#menu- ul[role="listbox"] {
  border-top: 4px solid var(--londolozi-blue);
}

.room-config-search {
  padding: 4px 0 0;
  width: 100%;
}

.room-config-select-dropdown {
  display: flex;
  flex-direction: row;
  padding: 8px 20px 8px 22px !important;
}

.search-config-headers {
  font-size: 15px;
  display: flex;
  flex-direction: column;
  padding: 0 0 3px;
  justify-content: center;
}

.search-config-incrementbox {
  display: flex;
  margin-left: auto;
}

.search-config-subtext {
  font-style: italic;
  color: #aaa;
  line-height: 1.1;
}

.search-config-box-done {
  margin: 8px 0 0;
  font-family: trajan;
  padding: 14px 12px !important;
}

.incr-dec-button {
  font-size: 16px;
}

.incrbuttonmiddle {
  font-size: 16px;
  font-family: trajan;
}

.incrbuttonmiddle {
  padding-top: 3px;
}

.search-config-incrementbox span button {
  font-family: Georgia, Times New Roman, Times, serif !important;
  font-size: 18px;
}

/*===== RESULTS ======*/

.booking-card {
  border-top: 1px solid #ddd;
  margin: 34px 0 0;
  padding: 32px 0 0;
}

.matching-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  grid-row-gap: 0;
  grid-column-gap: 0;
}

.match-card {
  min-height: 0;
}

.matching-list > div {
  overflow: hidden;
  flex: 1 1 33%;
  max-width: 350px;
  padding: 0 9px 30px;
  box-shadow: none !important;
  min-width: 320px;
}

.owl-carousel .owl-stage-outer {
  max-height: 205px !important;
}

.matching-list .owl-carousel.owl-drag .owl-item {
  background-position: center 41%;
}

.matching-list .owl-carousel {
  width: 100%;
  max-height: 220px;
  overflow: hidden;
}

.owl-dots {
  height: 20px;
  overflow: hidden;
  bottom: 13px !important;
  padding: 0 20px;
}

.CampCarouselButtonPropName {
  font-weight: normal;
  font-size: 19px;
  color: #000;
  line-height: 1;
  padding-bottom: 9px;
}

.CampCarouselButton {
  padding: 12px 4px;
  position: relative;
}

.booking-page-title > span:first-child,
.bookingmodalheaders span {
  font-size: 17px !important;
  color: #231f20 !important;
  line-height: 1 !important;
  text-transform: capitalize;
}

.booking-page-title > span:nth-child(2) {
  letter-spacing: 0;
  font-style: italic;
  padding: 0 0 19px;
  color: #a4a4a4 !important;
  font-size: 15px !important;
}

.booking-page-title {
  padding: 0 !important;
}

.matching-list + div {
  border-top: 1px solid #e5e5e5;
  margin: 14px 0 0;
  padding: 32px 0 0;
}

.owl-stage-outer::after {
  content: "";
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.05) 0%,
    rgba(0, 0, 0, 0) 20%,
    rgba(0, 0, 0, 0) 48%,
    rgba(0, 0, 0, 0.07) 76%,
    rgba(0, 0, 0, 0.3) 100%
  );
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 2;
  transition: 0.2s;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.07) inset;
}

.owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-prev {
  height: 42px;
  width: 42px;
  background: rgba(0, 0, 0, 0.2);
  text-shadow: 1px 1px 0 #000, 0px 0px 3px rgba(0, 0, 0, 0.4);
  top: 50%;
  margin-top: -21px;
  border-radius: 0;
  transition: 0.2s;
}

.owl-carousel .owl-nav button.owl-next span,
.owl-carousel .owl-nav button.owl-prev span {
  display: none;
}

.owl-carousel .owl-nav button.owl-next:hover,
.owl-carousel .owl-nav button.owl-prev:hover {
  text-shadow: 1px 1px 0 #000, 0px 0px 3px rgba(0, 0, 0, 0.2);
}

.owl-carousel .owl-nav button.owl-next::before,
.owl-carousel .owl-nav button.owl-prev::before {
  font-family: "londo";
  font-size: 25px;
  line-height: 1;
  color: white;
  opacity: 0.8;
  transition: 0.2s;
}

.owl-carousel .owl-nav button.owl-prev::before {
  content: "j";
}

.owl-carousel .owl-nav button.owl-next::before {
  content: "k";
}

/*===== PRIVACY POLICY ======*/

.booking-card-policy,
.booking-modal-policy-header {
  border-bottom: 1px solid #e2e2e2;
}

.booking-card-policy,
.booking-card-policy ~ div {
  max-width: 720px;
  margin: 0 auto;
}

.booking-card-policy ~ div:last-child,
.toggle-policy-content {
  padding-bottom: 24px;
  border-bottom: 1px solid #e2e2e2;
}

.booking-card-policy + div,
.toggle-policy-content {
  padding-top: 12px;
}

.booking-card-policy ~ div,
.toggle-policy-content {
  background: #fafafa;
}

.toggle-policy-content {
  padding: 6px 0 15px !important;
}

.toggle-policy-content ul {
  margin: 14px 30px !important;
}

.booking-card-policy > div {
  padding: 0 !important;
  width: 100%;
}

.booking-card-policy > div > span,
.booking-modal-policy-header > div > span {
  text-align: center;
  color: #333 !important;
}

.booking-card-policy > div > span:first-child,
.booking-modal-policy-header > div > span:first-child {
  font-style: italic;
  color: #888 !important;
  font-size: 15px !important;
  padding-bottom: 2px;
}

.booking-card-policy button,
.booking-modal-policy button {
  padding: 0 !important;
}

.booking-card-policy button svg,
.booking-modal-policy button svg,
.itinerary-details button svg {
  color: #787878 !important;
  height: 36px !important;
  width: 36px !important;
}

.policy-content::before,
.toggle-policy-content ul::before {
  content: "k";
  font-family: londo;
  position: absolute;
  left: 2px;
  color: #222;
  font-size: 14px;
  top: 1px;
}

.policy-content,
.toggle-policy-content ul {
  position: relative;
  padding-left: 32px;
  line-height: 1.7;
  margin: 0;
}

.policy-subheading {
  padding: 14px 30px !important;
}

.policy-subheading > span:first-child {
  font-family: "trajan";
  font-size: 15px !important;
  padding-bottom: 0px;
  text-transform: capitalize;
  color: rgb(128, 130, 133) !important;
  letter-spacing: 1px;
  line-height: 1.5 !important;
  padding-left: 30px;
}

/*===== BOOKING TABLE calendar ======*/

.table-drag-select tr.header td.month,
.table-drag-select tr.header td.price,
.table-drag-select tr.header td.room-type,
.table-drag-select td.day.weekday-date,
.table-drag-select td.day.weekday-name {
  background: #888888;
}

.calendarLegend {
  display: -webkit-box;
  padding-bottom: 15px;
}
.calendarlegendicon {
  width: 20px;
  display: -webkit-inline-box;
  height: 20px;
  border: 1px #888888 solid;
  line-height: 14px;
  text-align: -webkit-center;
  /* padding-left: 2px; */
}

.calendarlegendtext {
  width: 90%;
  display: inline-block;
  padding-left: 12px;
  /* line-height: 5px; */
  height: 100%;
  margin: 0;
  /* text-align: center; */
}
.calendar.booking-card::before {
  content: "Calendar";
  width: 100%;
  display: block;
  margin-bottom: 48px;
  margin-top: 2px;
  z-index: 5;
}

.camp-bar svg {
  height: 30px;
  width: 30px;
  color: #666 !important;
}

.calendar.booking-card {
  border-top: none;
  margin-top: 20px;
  padding-bottom: 20px;
}

.camp-bar {
  padding: 6px 1rem;
  font-size: 16px;
  background: #fafafa;
  border-style: solid;
  border-width: 0 0 1px 0;
  border-color: #ddd;
}

.camp-bar + div {
  border-bottom: 3px solid #e5e5e5;
}

.table-drag-select .room-type {
  font-size: 14px;
  font-style: italic;
  color: #888;
  padding: 9px 2px 9px 6px !important;
  line-height: 15px;
  width: 120px;
  min-height: 35px;
  border-bottom: 1px solid #dcdcdc;
}

.table-drag-select td.price {
  font-size: 14px;
  padding: 9px 6px !important;
  border: none;
  border-bottom: 1px solid #dcdcdc;
}

.table-drag-select .accomm-line td.day {
  border-color: #ddd;
}

.selected-booked {
  /* background-color: #2564a5; */
  background-color: #969696;
  color: #fff;
}

.table-drag-select.dates td.day {
  font-style: italic;
  font-size: 14px;
}

.table-drag-select.dates tr.header + tr td {
  padding: 6px 0 0;
  background: #999;
}

.table-drag-select.dates tr.header + tr + tr td {
  padding: 0 0 6px;
  background: #999;
}

.table-drag-select tr.header td.month {
  padding: 12px 0 10px;
  color: white;
  font-family: trajan;
  font-weight: normal;
  line-height: 17px;
  vertical-align: middle;
  font-size: 16px;
  letter-spacing: 0.5px;
}

.table-drag-select tr.header td.price,
.table-drag-select tr.header td.room-type {
  text-align: left;
  padding: 8px !important;
  font-style: italic;
  background: #999;
}

/*=============================================
=            MAKE A BOOKING                   =
=============================================*/

#booking-calendar-page .DateInput.DateInput_1 {
  width: 100%;
}

#booking-calendar-page .search-dates {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

#booking-calendar-page input[type="number"] {
  padding: 0;
  background: transparent;
  border: none;
  line-height: normal;
  font-family: trajan;
  color: #111 !important;
}

#booking-calendar-page .react-date-picker__inputGroup {
  padding: 12px;
  height: 40px;
  min-width: 200px;
}

#booking-calendar-page .react-date-picker__wrapper {
  border: 1px solid #d0d0d0;
  background: #fafafa;
}

#booking-calendar-page .booking-page {
  overflow: inherit;
}

#booking-calendar-page .select-month-header {
  width: auto;
  text-align: right;
  margin-bottom: 8px;
}

/*=============================================
=        			    CAMP MODAL                  =
=============================================*/

.modal-camp .booking-form::after,
.result-camp .CampCarouselButton::after {
  background-image: url("https://blog.londolozi.com/wp-content/themes/londolozi/img/relais-chateaux-logo-med.png");
  background-repeat: no-repeat;
  background-size: 100%;
  position: absolute;
  width: 65px;
  height: 70px;
  display: none;
}

.result-camp[data-name="tree-camp"] .CampCarouselButton::after,
.result-camp[data-name="private-granite-suites"] .CampCarouselButton::after,
.result-camp[data-name="pioneer-camp"] .CampCarouselButton::after,
.modal-camp[data-name="tree-camp"] .booking-form::after,
.modal-camp[data-name="private-granite-suites"] .booking-form::after,
.modal-camp[data-name="pioneer-camp"] .booking-form::after {
  content: "";
  display: block;
}

.result-camp .CampCarouselButton::after {
  right: 10px;
  top: 12px;
  z-index: 100000;
  width: 44px;
  height: 80px;
}

.modal-camp .booking-form::after {
  right: 32px;
  top: 25px;
  width: 50px;
  height: 68px;
}

.CampCarouselButton::after {
  background-image: url("https://blog.londolozi.com/wp-content/themes/londolozi/img/relais-chateaux-logo-med.png");
  background-repeat: no-repeat;
  content: "";
  position: absolute;
  right: 0;
  top: 0;
}

.bookingmodalheaders {
  padding: 1px 0 !important;
}

.bookingmodalheaders span {
}

.modal-dates {
  color: #a8a8a8;
  font-size: 14px;
  line-height: 1.2;
}

.bold-modal-header {
  font-weight: normal;
  font-size: 19.5px;
  color: #000;
  padding: 3px 0 2px;
}

.ReactModalPortal .ReactModal__Overlay {
  z-index: 2000;
  background-color: rgba(0, 0, 0, 0.65) !important;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  background: rgba(0, 0, 0, 0.65) !important;
}

.ReactModal__Body--open {
  overflow: hidden;
}

.ReactModal__Content.ReactModal__Content--after-open {
  width: 800px !important;
  max-width: calc(100% - 80px);
  border-radius: 0 !important;
  box-shadow: none !important;
  border: none !important;
  padding: 0 !important;
  height: auto !important;
  bottom: auto !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  max-height: calc(100vh - 80px);
  position: absolute !important;
}

.closemodalimage,
#agent-exit-button button,
.ReactModal__Content > button:first-child,
.Itinerary__body button > div:first-child {
  display: block;
  z-index: 1000;
  line-height: 20px;
  font-weight: normal;
  height: 30px;
  color: #3798fb;
  font-size: 30px;
  text-indent: 0;
  text-align: center;
  cursor: pointer;
  transition: 0.3s;
  opacity: 1;
  position: absolute;
  top: 21px;
  right: 18px;
  z-index: 10000;
  width: 30px;
  font-family: "londo" !important;
  font-size: 0;
  text-shadow: 1px 1px 1px #000, -1px -1px 0px rgba(255, 255, 255, 0.3);
}

.closemodalimage::after,
#agent-exit-button button::after,
.ReactModal__Content > button:first-child:after,
.Itinerary__body button > div:first-child:after {
  content: "I";
  font-size: 30px;
  text-shadow: none;
}

.closemodalimage::after {
  text-shadow: 1px 1px 1px #000, -1px -1px 0px rgba(255, 255, 255, 0.3);
}

.topimagecontainer::before {
  content: "";
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.05) 0%,
    rgba(0, 0, 0, 0) 20%,
    rgba(0, 0, 0, 0) 48%,
    rgba(0, 0, 0, 0.07) 76%,
    rgba(0, 0, 0, 0.3) 100%
  );
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 2;
  transition: 0.2s;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.07) inset;
}

.topimagecontainer {
  position: relative;
  width: 100%;
  height: 220px;
  overflow: hidden;
  object-fit: cover;
}

.topimagecontainer img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.confirm-button-bottom {
  position: relative;
  border: 1px solid;
  right: auto;
  top: auto;
  width: calc(100% - 275px) !important;
  float: none;
  margin: 20px 0 30px 275px !important;
  overflow: hidden;
  display: block;
  text-align: right;
  box-shadow: none !important;
}

.bold-modal-header {
  font-weight: normal;
  font-size: 19.5px;
  color: #000;
}

.room-config button svg {
  color: #ccc !important;
  height: 30px !important;
  width: 30px !important;
}

.booking-form {
  padding: 0 30px 30px;
  position: relative;
}

.room-config {
  padding: 0 10px 30px;
  border-bottom: 1px solid #e5e5e5;
  margin: 0 0 20px;
  flex-direction: row;
  flex-wrap: wrap;
  display: flex;
  position: relative;
  border-radius: 0 !important;
  height: auto;
}

/*===== HEADER ======*/

.room-config-header {
  overflow: hidden;
  width: 100% !important;
  position: relative;
  display: flex;
  flex: 1 0 100%;
}

.room-config-header > div {
  padding: 6px 0 !important;
  width: 100%;
  text-align: left;
}

.room-config-header span {
  font-size: 18px !important;
  font-weight: normal;
  color: black !important;
  position: relative !important;
  left: auto;
  right: auto;
  top: auto;
  bottom: auto;
  grid-template-columns: unset;
}

.room-config-header .room-config-remove {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 2px;
  right: 0;
  width: 36px !important;
  height: 36px !important;
  padding: 0 !important;
}

.room-config-remove button {
  padding: 0 !important;
  height: 36px !important;
}

/*===== LEFT ======*/

.room-config-left {
  flex: 0 0 266px;
  justify-content: start;
  text-align: left;
}

.roomconfig-image {
  width: 240px;
  height: 164px !important;
  left: 0;
  top: 4px;
  position: relative;
}

/*===== RIGHT ======*/

.room-config-right {
  flex: 1 1;
  flex-direction: column;
}

.room-guests {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0 0 8px;
}

.room-config-select {
  width: 18%;
  display: inline-block;
  float: right;
  margin: 0;
  flex: 1 0 33%;
  position: relative;
  padding: 30px 0 0 !important;
  justify-content: start;
  text-align: left;
}

.room-guests .bookingmodalheaders {
  position: absolute;
  top: 0;
  font-size: 15px;
}

.room-prices {
  display: flex;
  flex-direction: column;
}

.room-prices > div {
  left: auto;
  right: auto;
  top: auto;
  bottom: auto;
  position: relative;
  width: 100%;
  margin: 0 !important;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 !important;
}

.room-prices > div > span {
  padding: 0;
  display: flex;
  flex: 1 0 50%;
  font-size: 15px;
  font-weight: normal;
  position: relative;
  height: 36px;
  align-items: center;
}

.room-prices > div > span > div {
  position: absolute !important;
  left: 0 !important;
  top: 0 !important;
  width: 100% !important;
  height: 24px !important;
  background-position: left;
}

.room-prices > div > span:first-child,
.rooms-upper-table-totals tr td:first-child,
.upper-table-totals tr td:first-child {
  color: #a8a8a8;
  font-size: 14px !important;
}

.room-prices > .rate-override {
  font-family: Georgia, Times New Roman, Times, serif !important;
  padding: 8px 0 0 49% !important;
}

.italic-lower-number {
  right: auto;
  position: relative;
}

label {
  font-family: Georgia, Times New Roman, Times, serif !important;
  font-size: 15px;
  color: #a8a8a8 !important;
  margin-left: 6px;
}

.rate-override label,
.itinerary-checkbox label {
  margin: 0 0 0 -9px;
  font-size: 15px !important;
  width: auto !important;
  color: #a8a8a8 !important;
}
/*===== ADD ROOM ======*/

.warning-booking-modal-inner {
  color: red !important;
  padding: 6px 0 8px !important;
  font-size: 15px !important;
  text-align: left;
}

.warning-booking-modal {
  position: relative;
  width: 100%;
  left: 0;
  top: 0;
  font-weight: normal !important;
  color: red !important;
}

.add-another-room {
  border: none !important;
  border-bottom: 1px solid #e5e5e5 !important;
  width: 100%;
  right: auto;
  height: auto;
  margin: 0 0 10px !important;
  border-radius: 0 !important;
  padding: 13px 0 34px;
  display: flex !important;
  box-shadow: none !important;
  justify-content: end;
}

.add-another-room button {
  float: right;
  display: block !important;
  width: auto !important;
  border: 1px solid !important;
  padding: 0;
}

.add-another-room button span {
  padding: 0 40px !important;
}

/*===== TOTALS ======*/

.rooms-upper-table-totals,
.upper-table-totals {
  width: calc(100% - 275px) !important;
}

.rooms-upper-table-totals tr,
.upper-table-totals tr {
  border: none !important;
  height: auto !important;
}

.rooms-upper-table-totals td,
.upper-table-totals td {
  padding: 11px 0px !important;
  width: 50% !important;
  font-weight: normal;
  font-size: 15px !important;
  line-height: 1 !important;
  height: auto !important;
}

/*===== CALENDAR ======*/

.btn-movedays {
  width: 40px !important;
  min-width: 0 !important;
  border-radius: 0 !important;
}

.btn-movedays button > div > div::after {
  content: "j";
  text-transform: lowercase;
  font-family: "londo";
  font-size: 21px;
  position: absolute;
  line-height: 1;
  color: #333;
  opacity: 0.8;
  transition: 0.2s;
  left: 50%;
  margin: 0 0 0 -11px;
  top: 7px;
}

.calendar__header {
  margin-top: 20px;
}

.calendar__header > .btn-movedays:last-child button > div > div::after {
  content: "k";
}

.btn-movedays button > div > div svg {
  display: none !important;
}

.booking-card-dropdown-calendar > label,
.bookingdetail2-card > div > div > div > label,
.manage-booking-popup .ReactTable + div > div > div:last-child > div > label {
  top: 15px !important;
  font-style: italic;
  color: #aaa !important;
  letter-spacing: 0 !important;
  left: 0 !important;
  margin: 0 auto;
}

.booking-card-dropdown-calendar div button ~ div,
.bookingdetail2-card > div > div > div div button ~ div,
.manage-booking-popup
  .ReactTable
  + div
  > div
  > div:last-child
  > div
  div
  button
  ~ div {
  top: 1px;
}

.booking-card-dropdown-calendar div button,
.bookingdetail2-card > div > div > div div button,
.manage-booking-popup
  .ReactTable
  + div
  > div
  > div:last-child
  > div
  div
  button {
  padding: 0 !important;
  position: relative;
  top: 0 !important;
}

.booking-card-dropdown-calendar div button svg,
.bookingdetail2-card > div > div > div div button svg,
.manage-booking-popup
  .ReactTable
  + div
  > div
  > div:last-child
  > div
  div
  button
  svg,
.Itinerary__body button > div:first-child svg {
  display: none !important;
}

.booking-card-dropdown-calendar,
.bookingdetail2-card > div > div > div,
.manage-booking-popup .ReactTable + div > div > div:last-child > div {
  height: 50px !important;
  margin-top: -5px;
}

.booking-card-dropdown-calendar > div div,
.bookingdetail2-card > div > div > div > div div,
.manage-booking-popup .ReactTable + div > div > div:last-child > div > div div {
  top: 0 !important;
}

.booking-card-dropdown-calendar div button::after,
.bookingdetail2-card > div > div > div div button::after,
.manage-booking-popup
  .ReactTable
  + div
  > div
  > div:last-child
  > div
  div
  button::after {
  top: 15px;
  right: 12px;
}

.booking-card-dropdown-calendar hr,
.bookingdetail2-card > div > div > div hr,
.manage-booking-popup .ReactTable + div > div > div:last-child > div hr {
  bottom: 1px !important;
}

.booking-card-dropdown-calendar > div,
.bookingdetail2-card > div > div > div > div,
.manage-booking-popup .ReactTable + div > div > div:last-child > div > div {
  margin-top: 0 !important;
}

.cell-exact,
.selected-notbooked {
  background-color: #68b3ff;
}

.booked-selected {
  background-color: #258ef9;
}

/*=============================================
=         	DASHBOARD AGENT INFO       			 =
=============================================*/

/*===== REACT TABLE ======*/

.ReactTable .rt-td,
.ReactTable .rt-th {
  padding: 9px 8px;
}

.ReactTable .rt-thead .rt-td.-sort-asc,
.ReactTable .rt-thead .rt-th.-sort-asc {
  box-shadow: inset 0 4px 0 0 var(--londolozi-blue);
}

.ReactTable .rt-thead .rt-td.-sort-desc,
.ReactTable .rt-thead .rt-th.-sort-desc {
  box-shadow: inset 0 -4px 0 0 var(--londolozi-blue);
}

.ReactTable .-pagination .-pageInfo {
  font-style: italic;
  color: #888;
  font-size: 14px;
}

.ReactTable .-pagination .-btn {
  vertical-align: middle;
  line-height: 24px;
  padding-top: 7px;
  padding-bottom: 4px;
  border: 1px solid;
  background: white;
  transition: 0.2s;
}

.ReactTable .-pagination .-btn:not([disabled]):hover {
  background: #eee;
  color: black;
}

.ReactTable .-pagination {
  box-shadow: none;
  border: none;
  padding: 0;
  margin: 13px 6px 6px;
}

.ReactTable {
  font-size: 14px;
  border: none;
}

.ReactTable .rt-thead .rt-resizable-header {
  padding: 11px 1rem 9px;
  font-size: 16px;
  line-height: 20px;
  border: none;
}

.ReactTable .rt-thead.-headerGroups .rt-th {
  font-size: 14px;
  font-style: italic;
  color: #a4a4a4;
  padding: 9px 2px 11px 6px !important;
  line-height: 15px;
  min-height: 35px;
  background: white;
  border: none;
}

.ReactTable .rt-tbody .rt-tr-group {
  border-bottom: 1px solid #eee;
}

.ReactTable .rt-thead.-filters .rt-th::before {
  content: "q";
  text-transform: lowercase;
  font-family: londo;
  font-size: 21px;
  position: absolute;
  left: auto;
  color: #d5d5d5;
  right: 12px;
  top: 9px;
}

.ReactTable .rt-thead.-filters,
.ReactTable .rt-thead .rt-resizable-header {
  background: #f7f7f7;
}

.ReactTable .rt-thead.-filters .rt-th {
  background: none;
  border: none;
  padding: 4px 5px 10px;
}

.ReactTable .rt-thead.-header {
  box-shadow: none;
  border: none;
}

.ReactTable .rt-thead.-filters {
  border: none;
  border-bottom: 4px solid #e5e5e5;
  border-bottom: 4px solid var(--londolozi-blue);
  padding: 0 5px;
}

.ReactTable .rt-tbody .rt-td {
  border-right: none;
}

.ReactTable.-striped .rt-tr.-odd {
  background: rgba(0, 0, 0, 0.01);
}

.ReactTable .rt-thead.-headerGroups {
  border-bottom: none;
}

.ReactTable .rt-thead.-filters input {
  background: #fcfcfc;
}

/*===== AGENT INFO MODAL ======*/

#agent-exit-button button div {
  display: none;
}

.agent-info-table + button {
  margin: 0 30px 30px !important;
  border: 1px solid !important;
  display: block !important;
  float: left;
}

.agent-header > div {
  padding: 0 !important;
}

.agent-header > div > span:first-child {
  font-size: 18px !important;
}

.agent-header {
  padding: 20px 30px 0;
}

.agent-info-table {
  margin: 0 30px 30px;
  max-width: calc(100% - 60px);
}

#agent-exit-button button {
  top: 0px;
  right: -15px;
  text-shadow: none;
}

.agent-info-table td,
.agent-info-table th {
  border-bottom: 1px solid #eaeaea;
  text-align: left;
  padding: 8px 10px;
  background: white;
}

.agent-info-table tr:nth-child(2n + 1) {
  background: rgba(0, 0, 0, 0.03);
}

.agent-info-table tr td:first-child {
  font-style: italic;
  color: #777;
  font-size: 13px;
}

/*=============================================
=        		 	MANAGE BOOKING       					 =
=============================================*/

.ReactModal__Content > button:first-child {
  right: 10px;
  position: absolute !important;
  top: 10px;
}

.ReactModal__Content > p {
  margin: 30px;
}

.ReactModal__Content > p div {
  padding-top: 8px !important;
  font-size: 18px !important;
  font-weight: normal !important;
  color: #888;
  font-style: italic;
}

#view-manage-bookings .ReactTable .rt-thead.-headerGroups {
  height: 16px !important;
  overflow: hidden;
}

.manage-booking-popup {
  padding: 20px 30px 40px;
}

.manage-booking-popup .agent-header {
  padding: 20px 0 0;
}

.manage-booking-popup > * {
  border: none !important;
  height: auto !important;
  box-shadow: none !important;
  padding: 0 !important;
}

.manage-booking-popup label {
  color: #888;
  font-style: italic;
  margin-left: 0;
}

.manage-booking-popup > div h3 {
  color: #888;
  font-style: italic;
  font-size: 14px;
  margin: 13px 0 3px;
}

.manage-booking-popup .ReactTable .rt-thead.-headerGroups {
  display: none;
}

.ReactTable .rt-thead.-header,
.ReactTable .rt-tfoot {
  box-shadow: none !important;
}

.manage-booking-popup .ReactTable .rt-td,
.manage-booking-popup .ReactTable .rt-th {
  padding: 9px 6px;
  border-right: 1px solid rgba(0, 0, 0, 0.05);
}

.manage-booking-popup .ReactTable.-striped .rt-tr.-odd {
  background: transparent;
}

.manage-booking-popup .ReactTable .rt-thead .rt-resizable-header {
  padding: 9px 5px;
  font-size: 14px;
  line-height: 20px;
}

.manage-booking-popup .ReactTable .rt-table {
  border: 1px solid rgb(221, 221, 221);
  margin-bottom: 8px;
}

.manage-booking-popup .ReactTable {
  margin-top: 20px;
}

.manage-booking-popup h4 {
  margin: 20px 0 9px;
  color: #888;
  font-style: italic;
}

.bookingdetail2-card > div > div > div {
}

.bookingdetail2-card > div button span {
  height: 38px !important;
  padding: 0 30px !important;
}

.manage-booking-popup .ReactTable + div > div > div:last-child > div {
  margin: 28px 0;
}

.bookingdetail2-card {
  margin: 0;
}

.bookingdetail2-card > div > div > div {
  margin: 10px 0;
  float: left;
  max-width: none !important;
}

.bookingdetail2-card > div button div > span {
  width: 100%;
}

.bookingdetail2-card > div button div > span:first-child {
  border: 1px solid !important;
}

.bookingdetail2-card > div {
  display: flex;
  flex-direction: row;
}

.bookingdetail2-card > div > div {
  width: 50%;
}

.bookingdetail2-card button {
  height: 40px !important;
  justify-content: end;
  align-items: end;
  margin-left: auto !important;
  align-self: end;
  width: 200px;
}

/*===== ITINERARY ======*/

div[name="itinerary"] > .booking-card::before,
#view-add-agents::before,
#trade-information::before,
#view-manage-lodge:before {
  content: "Itinerary";
  width: 100%;
  display: block;
  margin-bottom: 48px;
}

div[name="itinerary"] > .booking-card > div {
  display: flex;
  flex-direction: column;
}

.itinerary-form::before {
  content: "Enter details";
  color: #888;
  padding-top: 15px;
  font-style: italic;
  text-align: center;
  width: 100%;
  display: block;
  margin: 25px 0 16px;
}

.itinerary-details > div > div {
  background: #fafafa;
  border-bottom: 1px solid #e2e2e2;
  border-top: none;
  padding-left: 26px !important;
}

.itinerary-details > div > div:first-child {
  background: white !important;
  border-bottom: 1px solid #e2e2e2 !important;
  border-top: none !important;
  padding: 14px 16px !important;
}

.itinerary-details > div > div:first-child div span {
  font-size: 14px !important;
  padding-bottom: 0;
  color: #333 !important;
}

.itinerary-textarea h4 {
  color: #888;
  font-style: italic;
  line-height: 1.1;
  font-size: 14px;
  margin: 20px 0 9px;
}

.itinerary-tables > div > div > span:first-child {
  font-weight: normal;
  font-size: 19.5px !important;
  color: #000 !important;
}

.Itinerary__header {
  background: #f7f7f7;
}

.itinerary-table-totals {
  margin-top: 0 !important;
}

.itinerary-table td,
.itinerary-table th {
  font-size: 15px;
  font-weight: normal;
  padding: 9px 5px;
  border-bottom: 1px solid #eee;
  position: relative;
}

.Itinerary__body tr th:first-child {
  color: #a8a8a8;
  font-size: 14px !important;
  line-height: 1 !important;
  font-style: italic;
}

.Itinerary__body button > div:first-child {
  top: 7px;
  left: 50%;
  transform: translate(-50%);
  z-index: 1;
}

.closemodalimage::after,
#agent-exit-button button::after,
.ReactModal__Content > button:first-child::after,
.Itinerary__body button > div:first-child::after {
  font-size: 25px;
}

.itinerary-dropdowns-fonts label {
  font-style: italic;
  color: #aaa !important;
}

.itinerary-tables > div > div {
  padding: 18px 0 8px !important;
}

.itinerary-table td > button {
  position: absolute !important;
  top: 1px;
  left: 0;
}

.itinerary-details {
  padding: 0 !important;
  border: none !important;
  border-radius: 0 !important;
  box-shadow: none !important;
}

.itinerary-checkbox {
  margin: 22px 0 40px !important;
}

.itinerary-checkbox label {
  font-style: italic;
}

.submitbookingrequest button div {
  height: auto !important;
}

.submitbookingrequest button span {
  font-size: 16px !important;
  padding: 12px 28px 8px !important;
}

.itinerary-form,
.submitbookingrequest {
  max-width: 800px;
  margin: 0 auto;
}

.itinerary-form-extra > .itinerary-fieldgroup {
  width: 50% !important;
}

.itinerary-form-extra > .agencytoggle {
  width: 100% !important;
}

.itinerary-form-extra > .agencytoggle > .switchAgency {
  height: 50px;
  width: fit-content;
  /* padding-left: 300px; */
  color: #a8a8a8;
  font-size: 14px !important;
  line-height: 1 !important;
  font-style: italic;
}

.itinerary-form-extra > .agencytoggle > .switchAgency > div > button {
  color: rgba(0, 0, 0, 0.9);
  font-size: 1rem;
  width: 127px;
  border-right: 1px #a8a8a8 solid;
}

.itinerary-form-extra
  > .agencytoggle
  > .switchAgency
  > div
  > button:first-child {
  border-left: 1px #a8a8a8 solid;
}

.submitbookingrequest button {
  max-width: 340px;
}

.submitbookingrequest {
  text-align: center;
  box-shadow: none !important;
}

.itinerary-textarea {
  margin-bottom: 36px !important;
}

.itinerary-details button {
  padding: 0 !important;
}

.booking-card > div > .itinerary-dropdowns-fonts {
  max-width: 800px !important;
  margin: 0 auto 26px;
  width: 100% !important;
}

.booking-card > div > .itinerary-dropdowns-fonts input,
.booking-card > div > .itinerary-dropdowns-fonts > div {
  width: 256px !important;
}

.booking-card > div > .itinerary-dropdowns-fonts > div hr {
  max-width: 256px !important;
}

.loadingpopup span {
  font-size: 15px !important;
  font-style: italic;
  color: #666 !important;
}

/*=============================================
=        						LOGIN       						 =
=============================================*/

.authentication-card {
  max-width: 800px;
  width: 100%;
  box-shadow: none !important;
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1 0 100%;
  justify-content: center;
  padding: 0 30px 60px;
  min-width: 316px !important;
}

.button-line,
.field-line {
  padding: 16px 0 10px;
  position: relative;
}

.authentication-card__heading {
  margin: 28px 0 8px 0;
  padding: 16px;
}

.button-login {
  width: auto !important;
  min-width: 0px !important;
  transition: 0.3s !important;
  box-shadow: none !important;
  margin: 3px 0 6px !important;
}

.button-login > button div span,
.submitbookingrequest button span {
  padding: 10px 28px 8px !important;
  line-height: 1.5;
}

.button-login > button,
.submitbookingrequest button {
  border-radius: 0 !important;
  height: auto !important;
  line-height: 40px !important;
}

.authentication-card .field-line > div {
  height: 50px !important;
  max-width: 256px !important;
  width: 100% !important;
}

.authentication-card label {
  font-size: 14px !important;
  transform: none !important;
  margin: 0 !important;
  top: -11px !important;
  font-style: italic;
  transform-origin: 0 !important;
}

.authentication-card .field-line input {
  margin-top: 0 !important;
}

.button-login > button:hover > div > div,
.submitbookingrequest button:hover div div {
  background-color: transparent !important;
}

.button-login > button > div > div,
.submitbookingrequest button:hover div div {
  height: auto !important;
}

.button-login > button:hover,
.submitbookingrequest button:hover {
  opacity: 0.85;
}

.authentication-card form > div:last-child {
  padding: 0 !important;
  margin-top: -8px;
}

.authentication-card form > .button-line + div:last-child {
  padding: 16px !important;
}

.authentication-card .field-line button {
  width: 40px !important;
  text-align: right;
  position: absolute !important;
  right: 0;
}

.authentication-card button svg {
  color: #888 !important;
}

.authentication-card form > div {
  color: #999 !important;
  font-style: italic;
}

.authentication-card form > div.field-line,
.authentication-card form > div.button-line {
  font-style: normal !important;
}

p.error-message {
  background-color: #fff4f2;
  border: 1px solid #c00;
  border-radius: 0;
  color: #555;
  font-style: italic;
  text-align: center;
  font-size: 15px;
  line-height: 1.5;
  padding: 16px 15px 18px;
  margin: 0 0 30px;
}

.field-line > div > div {
  bottom: 0 !important;
  text-align: left;
  font-style: italic;
  color: rgb(224, 36, 22) !important;
}

/*=============================================
=        						ADD AGENT       						 =
=============================================*/

#view-add-agents::before {
  content: "Add Agents";
}

#view-add-agents > .booking-card:first-child {
  border-top: none;
  margin: 0 !important;
  padding: 0;
}

#view-add-agents > .booking-card > div:first-child {
  padding: 0 0 8px !important;
}

#view-add-agents > .booking-card > div:first-child > span:first-child {
  font-size: 19px !important;
}

#view-add-agents > .booking-card th {
  font-style: italic;
  font-size: 14px !important;
}

#view-add-agents table {
  font-family: Georgia, Times New Roman, Times, serif !important;
  margin: 0 0 10px;
}

#view-add-agents > .booking-card td {
  font-size: 14px !important;
}

#view-add-agents tr {
  border-bottom: 1px solid #eee !important;
}

.consultant-search-wrap > div {
  flex: 1 0;
}

.consultant-search-wrap > button {
  width: 50px;
}

.consultant-search-wrap > div[mode="indeterminate"] {
  width: 50px !important;
  display: block !important;
  flex: 0 0 50px;
}

.consultant-search-wrap {
  display: flex;
  flex-direction: row;
}

.consultant-search-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
}

#view-add-agents label {
  margin-left: 0 !important;
}

.consultant-search-wrap > div > input,
.consultant-search-wrap > div > div > hr {
  max-width: 256px !important;
}

.agent-search-wrap.-btn,
.consultant-search-wrap {
  margin: 0 0 30px;
}

.agent-search-wrap + div,
.consultant-search-wrap + div {
  font-style: italic;
  text-align: center;
  margin: 0 0 14px;
}

.agent-search-wrap + div > div,
.consultant-search-wrap + div > div {
  color: #aaa !important;
  font-size: 14px;
}

.generate-token-button {
  margin: 30px auto;
  width: 180px;
  height: 40px !important;
}

.generate-token-button > button {
  border: 1px solid !important;
  height: 40px !important;
}

#view-add-agents .email-field ~ button {
  border: 1px solid #333 !important;
  margin: 30px auto !important;
  width: 180px;
  height: 40px !important;
  min-width: 180px !important;
  display: block !important;
}

.email-field {
  margin: 0 0 30px;
  width: 33% !important;
}

.email-field input,
.email-field hr {
  max-width: 256px !important;
}

.DraftEditor-editorContainer {
  background-color: #fafafa;
  border: 1px solid transparent;
  position: relative;
  z-index: 1;
}

/*=============================================
=      			EDIT LODGE DISPLAY     					 =
=============================================*/

.content .lodge-page {
  padding: 10px;
}

#view-manage-lodge:before {
  content: "Edit Lodge Display";
}

.toggle-lodgeoptions label {
  color: #000 !important;
  font-size: 17px;
  padding: 0 0 6px;
  margin-left: 0;
}

.edit-lodge-policy > div {
  color: #888;
  font-style: italic;
  font-size: 14px;
  margin: 13px 0 8px;
  text-align: center;
}

.edit-lodge-policy .policy {
  margin: 0 0 7px;
}

.edit-lodge-policy textarea {
  background: rgb(245, 245, 245) none repeat scroll 0% 0% !important;
  padding: 10px 15px !important;
}

.edit-lodge-policy textarea + div {
  background: white !important;
  padding: 14px 20px 8px !important;
  margin: 0 10px !important;
}

.rate-group-container .toggle-lodgeoptions label {
  color: #888 !important;
  font-style: italic;
  font-size: 15px;
  padding: 0;
}

.rate-group-container > div > div > div {
  font-style: italic;
  color: #888 !important;
  padding: 0 !important;
}

.rate-group-container > div > div > div > div {
  font-style: normal;
}

.edit-lodge > div {
  border: none !important;
  background: none !important;
  margin: 0 !important;
  padding: 24px 30px 22px !important;
  border-radius: 0 !important;
}

.rate-group-container > div {
  flex: 1 0;
  padding: 14px 20px 6px !important;
  box-shadow: none !important;
  border: 1px solid #ddd;
  background: #fafafa !important;
  border-right: none;
}

.rate-group-container > div:last-child {
  border-right: 1px solid #ddd;
}

.edit-lodge-room > div {
  border: none !important;
  background: none !important;
  margin: 0 !important;
  padding: 0 0 29px !important;
}

.edit-lodge-room label {
  margin: 0 !important;
}

.edit-lodge-room > div > div {
  padding: 0 !important;
}

.edit-lodge > div > div > .toggle-lodgeoptions label {
  text-align: center;
}

.edit-lodge > div > div > .toggle-lodgeoptions label {
  text-align: center;
  font-family: "trajan" !important;
  font-size: 16px;
  line-height: 1 !important;
  letter-spacing: 1px;
  color: #555 !important;
  font-style: normal !important;
  text-transform: capitalize !important;
  padding: 10px 10px 10px 46px !important;
}

.edit-lodge > div > div {
  padding: 0 !important;
}

.edit-lodge {
  margin: 0 0 40px;
}

.edit-lodge-room > div > div .toggle-lodgeoptions + div {
  padding: 0 !important;
}

.edit-lodge > div > div .toggle-lodgeoptions + div {
  padding: 0 !important;
}

.rate-group-container > div > div > div > div > div {
  width: 100%;
}

.edit-lodge-policy textarea + div,
.edit-lodge-policy textarea + div button {
  font-family: "trajan" !important;
  background: white !important;
  height: 40px !important;
  line-height: 40px !important;
  font-size: 16px !important;
  outline: none !important;
}

.edit-lodge-policy textarea + div button {
  margin: 0 15px !important;
  outline: none !important;
  border: 1px solid black !important;
  padding: 0 15px !important;
}

/*=============================================
=        						Trade Content       						 =
=============================================*/

#trade-information::before {
  content: "Trade Information";
  text-align: left;
  font-size: 25px;
  margin-bottom: 35px;
}

#trade-information div,
.fontOverride {
  font-family: "NonBreakingSpaceOverride", "Hoefler Text",
    "Baskerville Old Face", Garamond, "Times New Roman", serif !important;
}

#trade-information-h2 {
  text-align: left;
  margin-bottom: 25px;
}

#remoteText p,
#remoteText li {
  font-family: "NonBreakingSpaceOverride", "Hoefler Text",
    "Baskerville Old Face", Garamond, "Times New Roman", serif !important;
}

#remoteText h4,
#remoteText h3,
#remoteText h2,
#remoteText h1 {
  font-weight: bold;
  font-family: "NonBreakingSpaceOverride", "Hoefler Text",
    "Baskerville Old Face", Garamond, "Times New Roman", serif !important;
}

.gallery {
  display: flex;
  flex-flow: row wrap;
  justify-content: left;
  margin-bottom: calc(1.5 * 1rem);
}

.gallery-item {
  display: inline-block;
  margin-right: 16px;
  margin-bottom: 16px;
  text-align: center;
  vertical-align: top;
  width: 100%;
}

.gallery-columns-2 .gallery-item {
  max-width: calc((100% - 16px * 1) / 2);
}

.gallery-columns-2 .gallery-item:nth-of-type(2n + 2) {
  margin-right: 0;
}

.gallery-columns-3 .gallery-item {
  max-width: calc((100% - 16px * 2) / 4);
}

.gallery-columns-3 .gallery-item:nth-of-type(3n + 3) {
  margin-right: 0;
}

.gallery-columns-4 .gallery-item {
  max-width: calc((100% - 16px * 3) / 4);
}

.gallery-columns-4 .gallery-item:nth-of-type(4n + 4) {
  margin-right: 0;
}

.gallery-columns-5 .gallery-item {
  max-width: calc((100% - 16px * 4) / 5);
}

.gallery-columns-5 .gallery-item:nth-of-type(5n + 5) {
  margin-right: 0;
}

.gallery-columns-6 .gallery-item {
  max-width: calc((100% - 16px * 5) / 6);
}

.gallery-columns-6 .gallery-item:nth-of-type(6n + 6) {
  margin-right: 0;
}

.gallery-columns-7 .gallery-item {
  max-width: calc((100% - 16px * 6) / 7);
}

.gallery-columns-7 .gallery-item:nth-of-type(7n + 7) {
  margin-right: 0;
}

.gallery-columns-8 .gallery-item {
  max-width: calc((100% - 16px * 7) / 8);
}

.gallery-columns-8 .gallery-item:nth-of-type(8n + 8) {
  margin-right: 0;
}

.gallery-columns-9 .gallery-item {
  max-width: calc((100% - 16px * 8) / 9);
}

.gallery-columns-9 .gallery-item:nth-of-type(9n + 9) {
  margin-right: 0;
}

.gallery-item:last-of-type {
  padding-right: 0;
}

.gallery-caption {
  display: block;
  font-size: 0.71111em;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  line-height: 1.6;
  margin: 0;
  padding: 0.5rem;
}

.gallery-item > div > a {
  display: block;
  line-height: 0;
  box-shadow: 0 0 0 0 transparent;
}

.gallery-item > div > a:focus {
  box-shadow: 0 0 0 2px #0073aa;
}

/*root: {*/
/*	flexGrow: 1,*/
/*},*/
.galleryPaper {
  color: rgba(0, 0, 0, 0.54);
  padding: 16px;
  text-align: left;
}
.galleryImg {
  margin: auto;
  max-width: 100%;
}

/* SWITCH */
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.switch-wrapper {
  display: "flex";
  flex-direction: "row";
  align-items: "center";
}

.switch-wrapper > .text {
  padding-left: 1.5rem;
  font-style: italic;
}

.switch-wrapper > .text > a {
  cursor: pointer;
}
