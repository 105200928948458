// Colors

$dark-green: #00a699;
$light-green: #33dacd;
$light-yellow: rgb(250, 250, 134);
$off-black: #20222b;
$off-white: #a5afd7;
$blue: #3c4251;
$light-blue: #464b5e;
$dark-blue: #333745;
$purple: #8357c5;
// Spacing
$xs-size: 1rem;
$s-size: 1.3rem;
$m-size: 1.6rem;
$l-size: 3.2rem;
$xl-size: 4.8rem;
$side-bar-width: 25rem;
$desktop-breakpoint: 45rem;
