.matching-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 2em;
  grid-column-gap: 2em;
}

.match-card {
  display: flex;
  min-height: 10em;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
}

.exact-card {
  background-color: $light-green;
}
