.generate-token-button {
  display: flex;
  justify-content: center;
}

.search-agent > div > hr:nth-child(2) {
  border-bottom-color: #3798fb !important;
}

[class^="consultant-search-"] > div > div > hr:nth-child(2) {
  border-bottom-color: #3798fb !important;
}

.email-field > div > hr:nth-child(2) {
  border-bottom-color: #3798fb !important;
}
