.authentication-card {
  margin: 0 auto;
  text-align: center;
  width: 70rem;
}

.authentication-card__heading {
  padding: $m-size;
}

.button-login > button {
  background: #3798fb !important;
}

.field-line > div > div > hr:nth-child(2) {
  border-bottom-color: #3798fb !important;
}

.itinerary-dropdowns-fonts > div > hr:nth-child(2) {
  border-bottom-color: #3798fb !important;
}
