.table-drag-select {
  /* Stretch horizontally to fill parent */
  width: 100%;
  /* Keep columns same width */
  table-layout: fixed;
  /* Disable text selection */
  user-select: none;
  /* Center in parent */
  margin-left: auto;
  margin-right: auto;
  /* Remove space between border */
  border-collapse: collapse;
}

.table-drag-select tr {
  border: none;
}

.table-drag-select tr.header td.month {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 10px;
  background: rgb(55, 152, 251);
  font-size: 14px;
  color: white;
  border: 1px solid rgb(55, 152, 251);
  border: 1px solid white;
}

.table-drag-select tr.header td.price,
.table-drag-select tr.header td.room-type {
  background: rgb(55, 152, 251);
  border: 1px solid white;
  color: white;
}

.table-drag-select td.price,
.table-drag-select td.room-type {
  padding-left: 10px;
  padding-right: 10px;
}

.table-drag-select td.price {
  border-right: 1px solid rgba(220, 220, 220, 1);
}

/* Table cells */
.table-drag-select td {
  text-align: center;
  /* Cut off long strings of text with ... */
  text-overflow: ellipsis;
  overflow: hidden;
  /* Make transparent gaps between cells. Better than relying on margin
    * because clicking an element's margin doesn't trigger events */
  border: 0.1rem solid transparent;
  background-clip: padding-box;
  /* Make cells reasonable size */
  line-height: $s-size;
  /* Shows more text when text too big to fit into cell */
  padding-left: 0rem;
  padding-right: 0rem;
}

.table-drag-select td.day {
  padding-top: 4px;
  padding-bottom: 4px;
  border-top: none;
  border-bottom: none;
}

.table-drag-select td.day:last-child {
  border-right: none;
}

.table-drag-select td.day.weekday-date,
.table-drag-select td.day.weekday-name {
  background: rgb(55, 152, 251);
  color: white;
}

/* Cells that can be interacted with */
.table-drag-select td.cell-enabled {
  //background-color: #ddd;
  // background: yellow;
  /* Make the cursor a + symbol */
  cursor: cell;
  border-right: 1px solid rgba(220, 220, 220, 1);
  border-bottom: 1px solid rgba(220, 220, 220, 1);
}

/* Cells that can be interacted with */
.booked {
  background-color: rgb(150, 150, 150);
  color: white;
  // border: 1px solid rgb(200, 200, 200) !important;
  /* Make the cursor a + symbol */
}

.booked-selected {
  background-color: rgb(55, 152, 251);
  color: white;
  // border: 1px solid rgb(200, 200, 200) !important;
  /* Make the cursor a + symbol */
}

/* Cells that cannot be interacted with */
.table-drag-select td.cell-disabled {
  text-align: left;
}

/* Cells that have been selected */
.table-drag-select td.cell-selected {
  background-color: #a9a9a9;
}

/* Cells that are in the process of being selected */
.table-drag-select td.cell-being-selected {
  /* This is the blue color you see when you select text with the cursor */
  background: rgb(55, 152, 251);
  color: white;
}

.table-drag-select .room-type {
  width: 10rem;
}
.table-drag-select .price {
  width: 7rem;
}

.calendar__header {
  display: flex;
  justify-content: space-between;
}

.dates tr td {
  border: 0.1rem solid #e7e7e7;
  font-weight: 500;
}

.camp-bar {
  display: flex;
  justify-content: space-between;
  padding: 0.2rem $xs-size;
  align-items: center;
  // font-weight: 600;
  font-size: $m-size;
  color: rgba(55, 152, 251, 1);

  background: rgba(240, 240, 240, 1);
  border-bottom: 1px solid;
}

.notes-textarea {
  width: 100%;
}

.notesbooking-textarea {
  width: 95%;
}

.btn-movedays {
  box-shadow: none !important;
  margin-bottom: 20px;
  border: 1px solid rgba(220, 220, 220, 1);
}

.selected-booked {
  background-color: rgb(0, 53, 199);
  color: white;
}

.selected-notbooked {
  color: white;
  background-color: rgb(125, 189, 253);
}

.cell-exact {
  color: white;
  background-color: rgb(125, 189, 253);
}

.cell-close {
  background-color: $light-yellow;
}

.blocked {
  color: white;
  background-color: darkslategrey;
}
