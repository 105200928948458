.booking-page {
  width: 132rem;
  margin: auto;
  height: 65px;
  font-family: Georgia, "Times New Roman", Times, serif;
}

.lodge-page {
  width: 132rem;
  margin: auto;
  height: 65px;
  font-family: Georgia, "Times New Roman", Times, serif;
}

.booking-card,
.booking-card__warning {
  margin-bottom: $l-size;
  /*padding: $s-size;*/
  padding-left: 30px;
  padding-right: 30px;
  // padding-top: 110px;
  // padding-bottom: 0px;
  box-shadow: none !important;
  border-top: 1px solid rgba(220, 220, 220, 1);
  font-family: Georgia, "Times New Roman", Times, serif;
  background: rgb(255, 255, 255) !important;
}

.content .lodge-page {
  padding: 30px 30px 0px 30px;
  overflow: hidden;
  height: auto;
  display: block;
  width: 100%;
  min-height: 100%;
}

.content .lodge-page .lodge-page {
  padding: 0;
}

.booking-card-searchbar {
  margin-bottom: $l-size;
  /*padding: $s-size;*/
  //padding-left: 30px;
  //padding-right: 30px;
  padding-top: 20px;
  // padding-bottom: 0px;
  box-shadow: none !important;
  //border: 1px solid rgba(220, 220, 220, 1);
  background: rgb(255, 255, 255) !important;
  border: none;
  // text-align: center;
  width: 525px;
  padding-bottom: 40px;
}

.config-dropdown-hidden {
  display: none !important;
  padding-left: 20px;
}
.booking-card-search-parent {
  text-align: -webkit-center;
  font-family: Georgia, "Times New Roman", Times, serif;
}
.booking-card-dropdown-calendar {
  font-family: Georgia, "Times New Roman", Times, serif;
}

.room-config-select-dropdown {
  margin-left: 0px;
  margin-right: 15px;
  width: 100%;
  display: inline-table;
  padding-bottom: 0px !important;
}

.search-config-subtext {
  font-size: 14px;
  font-family: Georgia, "Times New Roman", Times, serif;
  color: rgb(184, 184, 184);
  padding-top: 0px;
  margin-top: 0px;
  padding-bottom: 0px !important;
  margin-bottom: 0px;
}
.search-config-headers {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 20px;

  color: rgb(34, 34, 34);
  float: left;
  padding-top: 4px;
  /* padding-top: 5px; */
  padding-bottom: 0px !important;
}

.search-dates-header3 {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 17px;
  text-align: left;
  padding-bottom: 7px;
}
.search-dates-header4_1 {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 17px;
  float: left;
  width: 275px;
  text-align: left;
  padding-bottom: 7px;
}

.search-dates-header4_2 {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 17px;
  float: none;
  width: 275px;
  text-align: left;
  padding-bottom: 7px;
}

.search-dates-header5 {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 17px;
  color: rgb(184, 184, 184);
  width: 100%;
  text-align: right;
  padding-bottom: 25px;
}
.search-config-box-done {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 20px;
  border-top: 1px solid rgb(184, 184, 184);
  width: 100%;
  margin-top: 15px;
  float: right;
  text-align: right;
  padding-right: 25px !important;
}

.search-config-box-donebutton {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 20px;

  text-align: right;
  border: none;
  background-color: #fff;
  cursor: pointer;
  outline: 0;
  outline: none;
}

.search-config-box-donebutton:hover {
  outline: 0;
  outline: none;
}

.search-config-incrementbox {
  float: right;
}

.search-dates-header {
  display: block;
  width: 100%;
  text-align: center;
}

.search-dates-header2 {
  display: block;
  width: 100%;
  text-align: center;
  color: rgb(164, 164, 164);
  padding-top: 15px;
}

.search-button-box {
  display: block;
  float: right;
  width: 245px;
  border: 1px solid rgb(0, 0, 0);
  margin-right: 2px;
}

.search-config-box {
  display: flex;
  width: 100%;
  padding-bottom: 15px;
  //padding-left: 5px;
}

.search-dates-box {
  display: block;
  width: 100%;
  padding-bottom: 20px;
}

.search-config-box-guests {
  display: block;
  margin-right: 25px;
}

.search-config-box-rooms {
  display: block;
  margin-right: 25px;
}

.search-config-box-rooms {
  display: flex;
}

.search-button-style {
  border: 1px solid rgb(0, 0, 0);
}

.booking-card__warning {
  border: 1px solid rgb(223, 111, 111);
  background: rgb(255, 204, 204) !important;
}

.room-config__warning {
  border: 1px solid rgb(223, 111, 111);
  background: rgb(255, 218, 218) !important;
}
.calendar.booking-card {
  padding-top: 30px;
  padding-bottom: 30px;
  font-family: Georgia, "Times New Roman", Times, serif;
}

.search-dates {
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.bookingdetail-card {
  margin-bottom: $l-size;
  /*padding: $s-size;*/
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 15px;
  padding-bottom: 15px;
  box-shadow: none !important;
  border: 1px solid rgba(220, 220, 220, 1);
  background: rgba(252, 252, 252, 1) !important;
  text-align: left;
}

.bookingdetail2-card {
  margin-bottom: $l-size;
  /*padding: $s-size;*/
  padding-left: 30px;
  padding-right: 30px;
  margin-top: 15px;
  padding-bottom: 15px;
  box-shadow: none !important;
  border: 1px solid rgba(220, 220, 220, 1);
  background: rgba(252, 252, 252, 1) !important;
  text-align: center;
}

.owl-theme {
  width: 400px;
  height: 100%;
}

.dotsclass {
  width: 100%;
}

.owl-carousel .owl-nav {
  height: 100%;
  top: 0;
  position: absolute;
  width: 100%;
}

.owl-theme .owl-nav {
  margin-top: 0px;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
  height: 100%;
  width: 100%;
}
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next {
  background: none;
  color: white;
  border: none;
  padding: 0 !important;
  font: inherit;
  font-size: 25px;
  height: 100%;
  margin: 0px 0px 0px 0px;
}

.owl-carousel .owl-nav button.owl-prev:focus,
.owl-carousel .owl-nav button.owl-next:focus,
.owl-dot {
  outline: 0;
  outline: none;
}

.owl-carousel .owl-nav button.owl-prev:hover,
.owl-carousel .owl-nav button.owl-next:hover {
  background: none;
  color: white;
  border: none;
  padding: 0 !important;
  font: inherit;
  font-size: 25px;
}

.owl-theme .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  width: 100%;
  bottom: 10px;
}
.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
  *display: inline;
}
.owl-theme .owl-dots .owl-dot span {
  width: 5px;
  height: 5px;
  margin: 5px 7px;
  background: rgb(173, 173, 173);
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 200ms ease;
  border-radius: 30px;
}
.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #ffffff;
}

.owl-theme .owl-nav .owl-prev {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  width: 10%;
  top: 0px;
  left: 0px;
  background: #869791;
  color: #fff;
  text-decoration: none;
}

.owl-theme .owl-nav [class*="owl-"] {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
  background: #869791;
  color: #fff;
  text-decoration: none;
}

.owl-theme .owl-nav .owl-next {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  width: 10%;
  top: 0px;
  right: 0px;
  background: #869791;
  color: #fff;
  text-decoration: none;
}

.CampCarouselButtonPropName {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-weight: bold;
  font-size: 16px;
  padding-bottom: 12px;
}

.CampCarouselButtonCampName {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 14px;
  padding-bottom: 6px;
  font-style: italic;
}

.CampCarouselButton {
  font-family: Georgia, "Times New Roman", Times, serif;
  line-height: 14px;
  padding-left: 1em;
}

.view-button-test {
  line-height: 0px;
}

.CalendarDay__selected {
  border: 1px solid #e4e7e7;
}
.CalendarDay__selected_span {
  background: #3798fb;
  color: rgb(0, 0, 0); //text
  border: 1px solid #e4e7e7; //default styles include a border
}

.CalendarDay__selected {
  background: #3798fb;
  color: rgb(0, 0, 0);
}

// Will edit when hovered over. _span style also has this property
.CalendarDay__selected:hover {
  background: rgb(117, 186, 255);
  color: rgb(0, 0, 0);
  border: 1px solid #e4e7e7;
}

// Will edit when the second date (end date) in a range of dates
// is not yet selected. Edits the dates between your mouse and said date
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: rgb(117, 186, 255);
  border: 1px solid #e4e7e7;
}

.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
  background: #3798fb;

  border: 1px solid #e4e7e7;
}

.DateRangePickerInput__withBorder {
  border: none;
  text-align: center;
}

.DateInput.DateInput_1 {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 17px;
  color: black;
  border: 1px solid rgb(208, 208, 208);
  margin-right: 25px;
  background: rgb(250, 250, 250);
  width: 250px;
  text-align: center;
}

.DateRangePickerInput_arrow {
  display: inline-block;
  display: none;
  vertical-align: middle;
}

.DateRangePicker_picker {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 17px;
  z-index: 1200;
  background-color: #fff;
  position: absolute;
}

.DayPicker {
  background: rgb(255, 255, 255);
  position: relative;
  text-align: center;
  z-index: 100;
}

.DateInput_input {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 17px;
  text-align: center;
  font-weight: 200;
  font-size: 18px;
  line-height: 24px;
  color: #000000 !important;
  background-color: rgb(250, 250, 250);
  width: 100%;
  padding: 13px 12px 11px;
  border: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: 2px solid transparent;
  border-left: 0;
}

.dropdownsearch {
  border: 1px solid rgb(208, 208, 208);
  margin-right: 25px;
  // background: rgb(250,250,250);
  width: 250px;
  text-align: left;
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 17px !important;

  font-weight: 200;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  // background-color: rgb(250, 250, 250);
  height: 52px;
  outline: 0;
  outline: none;
  background-color: #fafafa !important;
  text-indent: 2em;
}

/*
    div[class^='MuiPaper']{
    border: 1px solid rgb(184, 184, 184);
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: 17px;
    }



    div[class^='MuiSelect']{
        font-family: Georgia, 'Times New Roman', Times, serif;
        font-size: 17px;
        outline: 0;
        outline: none;
        background-color: #fafafa !important;
    }
    
    div[class^='MuiSelect']:hover{

        outline: 0;
        outline: none;
        background-color: #fafafa !important;
    }
*/

.dropdownsearch:hover,
.dropdownsearch:focus {
  outline: 0;
  outline: none;
  background-color: #fafafa !important;
}

.dropdownsearch:first-child:hover,
.dropdownsearch:first-child:focus {
  outline: 0;
  outline: none;
  background-color: #fafafa !important;
}

.dropdownsearch > div > div:hover,
.dropdownsearch > div > div:focus {
  outline: 0;
  outline: none;
  background-color: #fafafa !important;
}
.room-config-search {
  outline: 0;
  outline: none;
  width: 270px;
}

.submitbookingrequest > button {
  background: rgb(55, 152, 251) !important;
}
