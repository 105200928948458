.ReactModalPortal .ReactModal__Overlay--after-open {
  z-index: 10;
}

.ReactModal__Content.ReactModal__Content--after-open {
  box-shadow: 3px 3px 4px 2px rgba(200, 200, 200, 0.5) !important;
  border: 1px solid rgba(200, 200, 200, 1) !important;
}
span {
  display: inline-block;
}
// .ReactModalPortal .ReactModal__Overlay--before-close {
//     opacity: 0;
// }

// .booking-modal {
//     outline: none;
//     background-color: white;

//     width: 60rem;
//     border: 0.1rem solid #888888;
//     box-shadow: 0.5rem 1rem 1rem #888888;
//     margin: 5rem auto auto auto;
//     // padding: $l-size;
//     text-align: center;
// }

.topimagecontainer {
  position: absolute;
  left: 0;
  top: 0;
}

.booking-form {
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
}

.closemodalimage {
  float: right;
  width: 20px;
  height: 20px;
  font-family: Arial, Helvetica, sans-serif;
  z-index: 2000;
  position: absolute;
  top: 15px;
  right: 20px !important;
  font-size: 30px;
  color: rgb(55, 152, 251);
  background-color: transparent;
  border-color: transparent;
  cursor: pointer;
  font-stretch: ultra-expanded;
}
.closemodalimage:focus {
  outline: 0;
  outline: none;
}

.booking-modal-policy {
  margin-bottom: $l-size;
  /*padding: $s-size;*/
  padding-left: 30px;
  padding-right: 30px;
  // padding-top: 110px;
  // padding-bottom: 0px;
  box-shadow: none !important;
  border: none;
  background: rgb(255, 255, 255) !important;
  padding-top: 20px;
}

.booking-modal-policy-header > div {
  padding-right: 0px !important;
}
// .booking-card-modal {
//     height: 65px;
//     background-color: rgb(192, 185, 185);
// }

.toggle-policy {
  float: right;
  top: -46px;
}

.modal-nights {
  font-style: italic;
  font-family: Georgia, "Times New Roman", Times, serif;
  padding-bottom: 5px;
}

.modal-dates {
  font-family: Georgia, "Times New Roman", Times, serif;
}

.toggle-policy-content {
  background: rgb(235, 234, 234);
  text-align: left;
}

.policyhead span {
  font-style: italic;
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 20px !important;
  color: rgb(146, 146, 146) !important;
  top: -13px;
}

.topimage {
  width: 657px;

  object-fit: contain;
}

.incrbuttonmiddle:hover {
  outline: 0;
  outline: none;
}

.incrbuttonmiddle {
  vertical-align: middle;
  // top: -6px;
  width: 40px;
  height: 4rem;
  font-size: 22px;
  // border-top: 1px solid black;
  // border-bottom: 1px solid black;
  border-top: 1px solid rgb(208, 208, 208);
  border-bottom: 1px solid rgb(208, 208, 208);
  position: relative;
  border-right: none;
  border-left: none;
  box-shadow: none !important;
  background-color: rgb(250, 250, 250);
}

.incr-dec-button {
  background-color: white;
  vertical-align: middle;
  width: 40px;
  height: 40px;
  font-size: 22px;
  box-shadow: none !important;
  border: 1px solid rgb(208, 208, 208);
}

.bookingmodalheaders {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 17px;
  padding-bottom: 10px;
  color: rgb(34, 34, 34);
}

.italic-lower {
  font-style: italic;
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 17px;
}

.italic-lower-number {
  right: -145px;
  position: absolute;
  font-weight: bold;
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 17px;
}

.upper-table-totals {
  position: relative;
  width: 60% !important;
  float: right !important;
}

.rooms-upper-table-totals {
  width: 60% !important;
  float: right !important;
}

.add-room-parent {
  position: absolute;
}

.max-capacity {
  position: absolute;
  right: 407px;
  top: 132px;
}

.lower {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 17px;
}

.rate-input-override {
  position: absolute;

  top: 0px;
  padding-left: 75px;
}

.warning-booking-modal {
  position: absolute;
  right: 221px;
  top: 325px;
  font-style: italic;
  font-weight: bold !important;
  font-family: Georgia, "Times New Roman", Times, serif;
}

.warning-booking-modal-inner {
  font-size: 17px !important;
  color: rgb(250, 120, 120) !important;
}

.rooms {
  position: relative;
  border: none;
}

.room-config {
  position: relative;
  padding: $xs-size $l-size;
  box-shadow: none !important;
  border: 1px solid rgba(220, 220, 220, 1);
  background: rgb(255, 255, 255) !important;
  height: 375px;
  border: none;
}

.total-client {
  right: 10px;
}

.total-lodge {
  right: 10px;
}

.total-commission {
  right: 10px;
}

.total-levy {
  right: 10px;
}

.add-another-room button {
  width: 100% !important;
}
.add-another-room {
  position: relative;
  min-width: 25% !important;
  border: 1px solid;
  right: -287px;
}

.add-another-room:hover {
  background: grey;
}

.confirm-button-bottom {
  position: relative;
  width: 25% !important;
  border: 1px solid;
  right: -641px;
  top: 46px;
  margin-bottom: 25px;
}

.confirm-button-raised {
  box-shadow: none !important;
  border: none;
}

.rate-override {
  font-style: italic;
  position: absolute;
  right: 333px;
  top: 290px;
  font-family: Georgia, "Times New Roman", Times, serif;
  width: 217px;
  font-size: 17px !important;
  font-family: Georgia, "Times New Roman", Times, serif;
}

.payable-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: lightblue;
}
// .booking-form {
//     max-height: 100rem;
//     overflow-y: scroll;
// }

.room-config-select {
  margin-left: 0px;
  margin-right: 15px;
  width: 18%;
  display: inline-block;
  float: right;
}

.roon-config-price-actual {
  font-size: 17px !important;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px !important;
  // background-color: rgba(220, 220, 220, 1);
  width: 57%;
  padding: 10px !important;
  // background-color: gainsboro;
  position: absolute;
  top: 160px;
  right: 0px;
  text-align: left;
  padding-left: 0px !important;
  font-family: Georgia, "Times New Roman", Times, serif;
}

.roon-config-price-commission {
  font-size: 17px !important;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px !important;
  // background-color: rgba(220, 220, 220, 1);
  width: 57%;
  padding: 10px !important;
  // background-color: gainsboro;
  position: absolute;
  top: 190px;
  right: 0px;
  text-align: left;
  padding-left: 0px !important;
}
.roon-config-price-levy {
  font-size: 17px !important;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px !important;
  // background-color: rgba(220, 220, 220, 1);
  width: 57%;
  padding: 10px !important;
  // background-color: gainsboro;
  position: absolute;
  top: 220px;
  right: 0px;
  text-align: left;
  padding-left: 0px !important;
}

.totals-booking-modal {
  font-style: italic;
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 17px !important;
}

.totals-booking-modal-value {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-weight: bold;
  font-size: 17px !important;
}

.roon-config-price-specific {
  font-style: italic;
  font-family: Georgia, "Times New Roman", Times, serif;
  padding-right: 120px;
}

.policy-confirmation {
  float: right;
  text-align: left;
  top: 10px;
  font-size: 13px;
  max-width: 368px !important;
}

.room-config-price-specific-value-price {
  font-weight: bold;
  font-family: Georgia, "Times New Roman", Times, serif;
  padding-left: 100px;
}

.room-config-price-specific-value-commission {
  font-weight: bold;
  padding-left: 45px;
  font-family: Georgia, "Times New Roman", Times, serif;
}

.room-config-price-specific-value-levy {
  font-weight: bold;
  padding-left: 25px;
  font-family: Georgia, "Times New Roman", Times, serif;
}

.confirm-button {
  margin: 0;
  font-family: trajan;
  color: #aaa;
  // padding: 14px 0 0 40px;
  font-size: 26px;
  letter-spacing: 4px;
  text-transform: uppercase;
}

.bold-modal-header {
  font-weight: bold;
  color: rgb(38, 38, 38);
}

.room-config-header span {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  font-weight: bold;
  font-size: 22px !important;
  left: 0px;
  top: 13px;
  position: absolute !important;
}

.roomconfig-image {
  top: 60px;
  left: 32px;
  width: 350px;
  height: 299px;
  position: absolute;
}

.room-config-remove {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 5px;
  right: 50px;
}

.room-config-accom {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.loadingpopup {
  background-color: white;
  width: 40rem;
  height: 20rem;
  border: none;
  border-radius: 5px;
  box-shadow: 10px 5px 5px black;
  margin: auto;
  text-align: center;
  z-index: 2300 !important;
}
