.bm-overlay {
  width: 25%;
  background: rgb(200, 0, 0);
}

// .bm-menu-wrap {
//     background-image: url(/wp-content/themes/londolozi/img/leopard-bg.jpg);
// }

.bm-menu-wrap {
  background-color: rgb(255, 254, 254);
  top: 100px;
  background-image: url(https://blog.londolozi.com/wp-content/themes/londolozi/img/leopard-bg.jpg);
  background-repeat: no-repeat;
  // background-position: bottom 192px right;
  background-size: 100%;
}
