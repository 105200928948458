.guest-info {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.guests {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.guest-card {
  margin: $s-size;
  width: 35rem;
  padding: $m-size;
}
#add-guest {
  align-self: center;
}
