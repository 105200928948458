.Itinerary__header {
  font-weight: 200;
}

.Itinerary__body {
  text-align: center;
  padding-bottom: 10rem;
}

.itinerary-table {
  border-collapse: collapse;
  width: 100%;
}

.itinerary-table th,
.itinerary-table td {
  padding: 8px;
  border-bottom: 1px solid #ddd;
}

.itinerary-dropdowns-fonts {
  font-family: Georgia, "Times New Roman", Times, serif;
}
